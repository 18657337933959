import {
  Grid,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
} from '@chakra-ui/react'
import { type AuctionWithHighestBid } from '@twlvxtwlv/supabase'
import type { Customer, Product, Taxon } from '@twlvxtwlv/tokenforge'
import { useTranslation } from 'next-i18next'
import { LoadMoreButton } from '../../../shared/load-more-button'
import { NewsletterSuccessModal } from '../../../shared/modals/newsletter-success-modal'
import { ProductCollection } from '../../../shared/product-collection'
import { Section } from '../../../shared/section'
import { FilterBar } from './filter-bar'

interface GalleryProps {
  artists: Customer[]
  auctionsWithHighestBid: AuctionWithHighestBid[]
  hasMoreArtists: boolean
  hasMoreProducts: boolean
  isLoadingMoreArtists: boolean
  isLoadingMoreProducts: boolean
  isLoadingProducts: boolean
  isNewsletterSubscriptionSuccessModalVisible: boolean
  onLoadMoreArtistsClicked: () => void
  onLoadMoreProductsClicked: () => void
  onNewsletterSubscriptionModalClose?: () => void
  products: Product[]
  productsPerPage: number
  selectedArtistId: string | null
  selectedGenreId: string | null
  taxa: Taxon[]
}

export function Gallery({
  artists,
  auctionsWithHighestBid,
  hasMoreArtists,
  hasMoreProducts,
  isLoadingMoreArtists,
  isLoadingMoreProducts,
  isLoadingProducts,
  isNewsletterSubscriptionSuccessModalVisible,
  onLoadMoreArtistsClicked,
  onLoadMoreProductsClicked,
  onNewsletterSubscriptionModalClose,
  products,
  productsPerPage,
  selectedArtistId,
  selectedGenreId,
  taxa,
}: GalleryProps) {
  const { t } = useTranslation(['common', 'nft-gallery'])
  const { isOpen, onClose } = useDisclosure({
    defaultIsOpen: isNewsletterSubscriptionSuccessModalVisible,
  })

  function handleOnClose() {
    onNewsletterSubscriptionModalClose?.()
    onClose()
  }

  return (
    <>
      <Section>
        <Tabs>
          <TabList>
            <Tab>{t('nft-gallery:gallery-title')}</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Stack w="full" maxW="full" mt={4} gap={4}>
                <FilterBar
                  artists={artists}
                  disableArtistFilter={false}
                  disableGenreFilter={false}
                  taxa={taxa}
                  hasMoreArtists={hasMoreArtists}
                  isLoadingMoreArtists={isLoadingMoreArtists}
                  onLoadMoreArtistsClicked={onLoadMoreArtistsClicked}
                  selectedArtistId={selectedArtistId}
                  selectedTaxonId={selectedGenreId}
                />
                <Grid
                  templateColumns={{
                    base: 'repeat(2, 1fr)',
                    sm: 'repeat(2, 1fr)',
                    md: 'repeat(3, 1fr)',
                    lg: 'repeat(5, 1fr)',
                    xl: 'repeat(6, 1fr)',
                    '2xl': 'repeat(7, 1fr)',
                    '3xl': 'repeat(8, 1fr)',
                    '4xl': 'repeat(10, 1fr)',
                    '5xl': 'repeat(12, 1fr)',
                    '6xl': 'repeat(14, 1fr)',
                    '7xl': 'repeat(16, 1fr)',
                  }}
                  gap={[2, 4, 4]}
                >
                  <ProductCollection
                    auctionsWithHighestBid={auctionsWithHighestBid}
                    isLoadingMoreProducts={isLoadingMoreProducts}
                    isLoadingProducts={isLoadingProducts}
                    productsWithAmount={products}
                    numberOfPlaceholders={productsPerPage}
                  />
                </Grid>
                <LoadMoreButton
                  isLoadingMore={isLoadingMoreProducts}
                  isVisible={hasMoreProducts}
                  onClick={onLoadMoreProductsClicked}
                />
              </Stack>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Section>

      <NewsletterSuccessModal
        isOpen={isOpen}
        onClose={handleOnClose}
        headingText={t('common:newsletter-success-modal-heading')}
        buttonText={t('common:newsletter-success-modal-button')}
        bodyText={t('common:newsletter-success-modal-text')}
      />
    </>
  )
}
