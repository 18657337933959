import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import type { Taxon } from '@twlvxtwlv/tokenforge'
import { useTranslation } from 'next-i18next'
import { useEffect, useState } from 'react'
import { HiChevronDown, HiOutlineCheck, HiXMark } from 'react-icons/hi2'
import { neueMachina } from '../../../../../../../fonts'

interface TaxonFilterProps {
  onResetTaxonFilter?: () => void
  onTaxonSelected?: (selectedTaxon: Taxon) => void
  selectedTaxonId?: string | null
  taxa: Taxon[]
}

export function TaxonFilter({
  onResetTaxonFilter,
  onTaxonSelected,
  selectedTaxonId,
  taxa,
}: TaxonFilterProps) {
  const { t } = useTranslation(['common', 'nft-gallery'])
  const { isOpen, onClose, onOpen } = useDisclosure()

  const [filterTitle, setFilterTitle] = useState<string>('')

  useEffect(() => {
    let title = ''
    const selectedGenre = taxa.find((genre) => genre.code === selectedTaxonId)

    if (selectedGenre) {
      title = selectedGenre?.name?.slice(0, 70) ?? ''
    }
    setFilterTitle(title)
  }, [selectedTaxonId, taxa])

  return (
    <Flex
      direction={['column', 'column', 'row']}
      align={['center', 'center', 'center']}
      gap={2}
      w="340px"
    >
      <Menu onOpen={onOpen} onClose={onClose} autoSelect={false}>
        <MenuButton
          as={Button}
          variant={isOpen ? 'txtBlack' : 'txtWhite'}
          w="340px"
          overflow="hidden"
          borderBottomRadius={isOpen ? '0' : '20px'}
          borderTopRadius={20}
          textTransform="initial"
        >
          <Flex align="center" justify="space-around" w="full">
            <Flex w="full" maxW="350px" overflow="hidden">
              <Text
                fontSize={['sm', 'sm', 'md']}
                fontWeight="bold"
                fontFamily={neueMachina.style.fontFamily}
              >
                {selectedTaxonId
                  ? `${t('nft-gallery:genre-title')}:`
                  : t('nft-gallery:select-genre-title')}
              </Text>
              &nbsp;
              <Text
                fontSize={['sm', 'sm', 'md']}
                as="span"
                fontWeight="400"
                fontFamily={neueMachina.style.fontFamily}
              >
                {selectedTaxonId ? filterTitle : ''}
              </Text>
            </Flex>
            <Flex justify="flex-end" w="50px">
              {isOpen ? <HiXMark width={8} height={8} /> : <HiChevronDown width={8} height={8} />}
            </Flex>
          </Flex>
        </MenuButton>
        <MenuList w="340px" maxW="100vw" p="0" zIndex={2}>
          <MenuOptionGroup
            defaultChecked={true}
            defaultValue={selectedTaxonId ?? 'all-genres'}
            type="radio"
          >
            <MenuItemOption
              key={'reset-genre-filter'}
              value={'all-genres'}
              onClick={() => {
                onResetTaxonFilter?.()
              }}
              icon={null}
              _active={{}}
              _focus={{}}
            >
              <Text fontWeight="bold" fontFamily={neueMachina.style.fontFamily}>
                {t('nft-gallery:all-genres-filter')}
              </Text>
            </MenuItemOption>
            {taxa?.map((genreOption) => (
              <MenuItemOption
                _checked={{
                  fontWeight: 'bold',
                }}
                key={genreOption.code}
                value={genreOption.code ?? undefined}
                onClick={() => {
                  onTaxonSelected?.(genreOption)
                }}
                icon={null}
                _active={{}}
                _focus={{}}
              >
                <Flex gap={2} align="baseline">
                  <Text fontFamily={neueMachina.style.fontFamily}>
                    {genreOption.name ?? undefined}
                  </Text>
                  {selectedTaxonId === genreOption.code ? <HiOutlineCheck /> : null}
                </Flex>
              </MenuItemOption>
            ))}
          </MenuOptionGroup>
        </MenuList>
      </Menu>
    </Flex>
  )
}
