import { Button, Heading, Text, VStack } from '@chakra-ui/react'
import { DefaultModal } from '../../../shared/modals/default-modal'

interface NewsletterSuccessModalProps {
  isOpen: boolean
  onClose: () => void
  headingText: string
  bodyText: string
  bodySmallText?: string
  buttonText: string
}

export function NewsletterSuccessModal({
  isOpen,
  onClose,
  headingText,
  bodyText,
  bodySmallText,
  buttonText,
}: NewsletterSuccessModalProps) {
  return (
    <DefaultModal
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
      body={
        <VStack gap={4} textAlign="center" fontSize={['xs', 'xs', 'sm']}>
          <Heading fontSize={['lg', 'lg', 'xl']}>{headingText}</Heading>
          <Text>{bodyText}</Text>
          {bodySmallText ? (
            <Text fontSize="xs" fontStyle="italic">
              {bodySmallText}
            </Text>
          ) : null}
          <Button variant="txtWhite" maxW="15rem" onClick={onClose}>
            {buttonText}
          </Button>
        </VStack>
      }
    />
  )
}
