import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Spinner,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import type { Customer } from '@twlvxtwlv/tokenforge'
import { useTranslation } from 'next-i18next'
import { useEffect, useState } from 'react'
import { HiChevronDown, HiOutlineCheck, HiXMark } from 'react-icons/hi2'
import { neueMachina } from '../../../../../../../fonts'

interface ArtistFilterProps {
  artists: Customer[]
  hasMoreArtists: boolean
  isLoadingMoreArtists?: boolean
  onArtistSelected?: (selectedArtist: Customer) => void
  onLoadMoreArtistsClicked?: () => void
  onResetArtistFilter?: () => void
  selectedArtistId?: string | null
}

export function ArtistFilter({
  artists,
  hasMoreArtists = false,
  isLoadingMoreArtists,
  onArtistSelected,
  onLoadMoreArtistsClicked,
  onResetArtistFilter,
  selectedArtistId,
}: ArtistFilterProps) {
  const { t } = useTranslation(['common', 'nft-gallery'])
  const { isOpen, onClose, onOpen } = useDisclosure()

  const [filterTitle, setFilterTitle] = useState<string>('')

  useEffect(() => {
    let title = ''
    const selectedArtist = artists.find((artist) => artist?.uuidAsString === selectedArtistId)

    if (selectedArtist) {
      title = selectedArtist?.profileName?.slice(0, 70) ?? ''
    }
    setFilterTitle(title)
  }, [selectedArtistId, artists])

  return (
    <Flex
      direction={['column', 'column', 'row']}
      align={['center', 'center', 'center']}
      gap={2}
      w="340px"
    >
      <Menu onOpen={onOpen} onClose={onClose} autoSelect={false}>
        <MenuButton
          as={Button}
          variant={isOpen ? 'txtBlack' : 'txtWhite'}
          w="340px"
          overflow="hidden"
          borderBottomRadius={isOpen ? '0' : '20px'}
          borderTopRadius={20}
          textTransform="initial"
        >
          <Flex align="center" justify="space-around" w="full">
            <Flex w="full" maxW="350px" overflow="hidden">
              <Text
                fontSize={['sm', 'sm', 'md']}
                fontWeight="bold"
                fontFamily={neueMachina.style.fontFamily}
              >
                {selectedArtistId
                  ? `${t('nft-gallery:artist-title')}:`
                  : t('nft-gallery:select-artist-title')}
              </Text>
              &nbsp;
              <Text
                fontSize={['sm', 'sm', 'md']}
                as="span"
                fontWeight="400"
                fontFamily={neueMachina.style.fontFamily}
              >
                {selectedArtistId ? filterTitle : ''}
              </Text>
            </Flex>
            <Flex justify="flex-end" w="50px">
              {isOpen ? <HiXMark width={8} height={8} /> : <HiChevronDown width={8} height={8} />}
            </Flex>
          </Flex>
        </MenuButton>
        <MenuList w="340px" maxW="100vw" p="0" zIndex={2} maxH="340px" overflowY="auto">
          <MenuOptionGroup
            defaultChecked={true}
            defaultValue={selectedArtistId ?? 'all-artists'}
            type="radio"
          >
            <MenuItemOption
              key={'reset-artist-filter'}
              value={'all-artists'}
              onClick={() => {
                onResetArtistFilter?.()
              }}
              icon={null}
              _active={{}}
              _focus={{}}
            >
              <Text fontWeight="bold" fontFamily={neueMachina.style.fontFamily}>
                {t('nft-gallery:all-artists-filter')}
              </Text>
            </MenuItemOption>
            {artists?.map((artistOption) => (
              <MenuItemOption
                _checked={{
                  fontWeight: 'bold',
                }}
                key={artistOption.uuidAsString}
                value={artistOption.uuidAsString ?? undefined}
                onClick={() => {
                  onArtistSelected?.(artistOption)
                }}
                icon={null}
                _active={{}}
                _focus={{}}
              >
                <Flex gap={2} align="baseline">
                  <Text fontFamily={neueMachina.style.fontFamily}>
                    {artistOption.profileName ?? undefined}
                  </Text>
                  {selectedArtistId === artistOption.uuidAsString ? <HiOutlineCheck /> : null}
                </Flex>
              </MenuItemOption>
            ))}
          </MenuOptionGroup>
          <>
            {isLoadingMoreArtists ? (
              <Flex justifyContent="center" alignItems="center">
                <Spinner />
              </Flex>
            ) : (
              <>
                {hasMoreArtists ? (
                  <MenuOptionGroup>
                    <MenuItemOption
                      onClick={onLoadMoreArtistsClicked}
                      closeOnSelect={false}
                      isChecked={false}
                      icon={null}
                      _active={{}}
                      _focus={{}}
                    >
                      <Text
                        color="txtDarkGray"
                        textDecoration="underline"
                        fontFamily={neueMachina.style.fontFamily}
                      >
                        {t('nft-gallery:load-more-artists')}
                      </Text>
                    </MenuItemOption>
                  </MenuOptionGroup>
                ) : null}
              </>
            )}
          </>
        </MenuList>
      </Menu>
    </Flex>
  )
}
