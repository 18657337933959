import type { ResponsiveValue } from '@chakra-ui/react'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import type { ReactNode } from 'react'

export interface DefaultModalProps {
  backgroundColor?: 'txtBlack' | 'white'
  body?: ReactNode
  closeOnOverlayClick?: boolean
  footer?: ReactNode
  header?: ReactNode
  isOpen: boolean
  onClose: () => void
  showCloseButton?: boolean
  size?: ResponsiveValue<'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | 'full'>
  textColor?: 'txtBlack' | 'white'
}

export function DefaultModal({
  backgroundColor,
  body,
  closeOnOverlayClick = true,
  footer,
  header,
  isOpen,
  onClose,
  showCloseButton = true,
  size = 'md',
  textColor,
}: DefaultModalProps) {
  return (
    <Modal
      size={size}
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={closeOnOverlayClick}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        w="full"
        bg={backgroundColor ?? 'txtBlack'}
        color={
          textColor ?? (!backgroundColor || backgroundColor === 'txtBlack') ? 'white' : 'txtBlack'
        }
        borderRadius={0}
        p={[0, 2, 4, 8, 12]}
        m="auto"
      >
        {showCloseButton ? <ModalCloseButton /> : null}
        <ModalHeader>{header}</ModalHeader>
        <ModalBody w="full" h="full" flexGrow={1} alignItems="center">
          {body}
        </ModalBody>
        <ModalFooter>{footer}</ModalFooter>
      </ModalContent>
    </Modal>
  )
}
