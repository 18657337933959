import { dehydrate, QueryClient } from '@tanstack/react-query'
import { serverSideTrackEvent } from '@twlvxtwlv/analytics/browser'
import { _trackEventOnServerWithLogging } from '@twlvxtwlv/analytics/server'
import { type AuctionWithHighestBid, getAuctionsWithHighestBid } from '@twlvxtwlv/supabase'
import { Gallery } from '@twlvxtwlv/ui/src/components/pages/marketplace/gallery'
import { Page } from '@twlvxtwlv/ui/src/components/shared/page'
import { extractFirstQueryParamValue } from '@twlvxtwlv/utils/routing'
import { _initDistinctId, initDistinctId } from '@twlvxtwlv/utils/src/cookie'
import type {
  GetServerSideProps,
  GetServerSidePropsContext,
  InferGetServerSidePropsType,
} from 'next'
import { useSession } from 'next-auth/react'
import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { setSwrCachingHeader } from '@twlvxtwlv/utils/src/headers'
import { getUseArtistsOptions, useArtists } from '@twlvxtwlv/hooks/src/tokenforge/use-artists'
import { getUseProductsOptions, useProducts } from '@twlvxtwlv/hooks/src/tokenforge/use-products'
import { getUseTaxaOptions, useTaxa } from '@twlvxtwlv/hooks'

interface GalleryPageProps {
  auctionsWithHighestBid: AuctionWithHighestBid[]
  productsPerPage: number
  taxaPerPage: number
  artistsPerPage: number
  initialSelectedArtistId: string | null
  initialSelectedGenreId: string | null
  locale: string
}

export default function GalleryPage({
  auctionsWithHighestBid,
  initialSelectedArtistId,
  locale,
  productsPerPage,
  taxaPerPage,
  initialSelectedGenreId,
  artistsPerPage,
}: InferGetServerSidePropsType<typeof getServerSideProps>) {
  const { t } = useTranslation(['common', 'nft-gallery'])
  const router = useRouter()

  const [selectedArtistId, setSelectedArtistId] = useState(initialSelectedArtistId)
  const [selectedGenreId, setSelectedGenreId] = useState(initialSelectedGenreId)

  const { status: authenticationStatus } = useSession()

  const {
    artists,
    fetchNextPage: loadMoreArtists,
    isFetchingNextPage: isLoadingMoreArtists,
    hasNextPage: hasMoreArtists,
  } = useArtists({
    itemsPerPage: artistsPerPage,
  })

  const {
    products,
    hasNextPage: hasMoreProducts,
    isFetchingNextPage: isLoadingMoreProducts,
    fetchNextPage: loadMoreProducts,
    isLoading: isLoadingProducts,
  } = useProducts({
    creatorUuid: selectedArtistId,
    taxonCode: selectedGenreId,
    locale,
    itemsPerPage: productsPerPage,
  })

  const { taxa } = useTaxa({
    itemsPerPage: taxaPerPage,
  })

  useEffect(() => {
    const selectedArtistId = extractFirstQueryParamValue(router.query.artist)
    const selectedGenreId = extractFirstQueryParamValue(router.query.genre)

    setSelectedArtistId(selectedArtistId)
    setSelectedGenreId(selectedGenreId)
  }, [router.query])

  const distinctId = initDistinctId()

  function handleOnNewsletterSubscriptionModalClose() {
    serverSideTrackEvent(
      { userId: distinctId },
      {
        eventName: 'sign-up-newsletter-completed',
      }
    )
    const { newsletter_subscription: _newsletter_subscription, ...query } = router.query
    router.replace({ query }, undefined, {
      shallow: true,
    })
  }

  const isNewsletterSubscriptionSuccessModalVisible =
    router.query.newsletter_subscription === 'success' ?? false

  return (
    <>
      <Head>
        <title>{t('meta-title-gallery')}</title>
        <meta name="description" content={t('meta-description-gallery') ?? undefined} />
      </Head>
      <Page bgColor="white" authenticationStatus={authenticationStatus}>
        <Gallery
          artists={artists}
          auctionsWithHighestBid={auctionsWithHighestBid}
          hasMoreArtists={hasMoreArtists ?? false}
          hasMoreProducts={hasMoreProducts ?? false}
          isLoadingMoreArtists={isLoadingMoreArtists}
          isLoadingMoreProducts={isLoadingMoreProducts}
          isLoadingProducts={isLoadingProducts}
          isNewsletterSubscriptionSuccessModalVisible={isNewsletterSubscriptionSuccessModalVisible}
          onLoadMoreArtistsClicked={loadMoreArtists}
          onLoadMoreProductsClicked={loadMoreProducts}
          products={products}
          productsPerPage={productsPerPage}
          selectedArtistId={selectedArtistId}
          selectedGenreId={selectedGenreId}
          taxa={taxa}
          onNewsletterSubscriptionModalClose={handleOnNewsletterSubscriptionModalClose}
        />
      </Page>
    </>
  )
}

export const getServerSideProps: GetServerSideProps<GalleryPageProps> = async ({
  req,
  res,
  query,
  locale = 'de',
}: GetServerSidePropsContext) => {
  setSwrCachingHeader(res)
  const distinctId = _initDistinctId({ req, res })

  const userAgent = req.headers['user-agent'] as string

  _trackEventOnServerWithLogging(
    { userId: distinctId },
    {
      eventName: 'gallery-page-visited',
    },
    userAgent
  )

  const productsPerPage = 24

  const taxaPerPage = 50
  const defaultTaxaStartPage = 1

  const artistsPerPage = 100

  const initialSelectedArtistId = extractFirstQueryParamValue(query.artist)
  const initialSelectedGenreId = extractFirstQueryParamValue(query.genre)

  const queryClient = new QueryClient()

  const getArtistsRequest = queryClient.prefetchInfiniteQuery(
    getUseArtistsOptions({
      itemsPerPage: artistsPerPage,
    })
  )

  const productsByPageRequest = queryClient.prefetchInfiniteQuery(
    getUseProductsOptions({
      itemsPerPage: productsPerPage,
      locale,
      creatorUuid: initialSelectedArtistId,
      taxonCode: initialSelectedGenreId,
    })
  )

  const taxaByPageRequest = queryClient.prefetchInfiniteQuery(
    getUseTaxaOptions({
      page: defaultTaxaStartPage,
      itemsPerPage: taxaPerPage,
    })
  )

  const auctionsWithHighestBidPromise = getAuctionsWithHighestBid()

  const [, , , auctionsWithHighestBidResult] = await Promise.allSettled([
    taxaByPageRequest,
    getArtistsRequest,
    productsByPageRequest,
    auctionsWithHighestBidPromise,
  ])

  const auctionsWithHighestBid =
    auctionsWithHighestBidResult.status === 'fulfilled' ? auctionsWithHighestBidResult.value : []

  return {
    props: {
      dehydratedState: JSON.parse(JSON.stringify(dehydrate(queryClient))),
      initialSelectedArtistId,
      initialSelectedGenreId,
      auctionsWithHighestBid,
      productsPerPage,
      taxaPerPage,
      artistsPerPage,
      locale,
      ...(await serverSideTranslations(locale, [
        'auction',
        'common',
        'footer',
        'navigation',
        'nft-details',
        'nft-gallery',
      ])),
    },
  }
}
