import { Flex } from '@chakra-ui/react'
import type { Customer, Taxon } from '@twlvxtwlv/tokenforge'
import { useRouter } from 'next/router'
import { ArtistFilter, TaxonFilter } from './filters'

interface FilterBarProps {
  artists?: Customer[]
  disableArtistFilter: boolean
  disableGenreFilter: boolean
  hasMoreArtists: boolean
  isLoadingMoreArtists?: boolean
  onLoadMoreArtistsClicked?: () => void
  selectedArtistId?: string | null
  selectedTaxonId?: string | null
  taxa?: Taxon[]
}

export function FilterBar({
  artists,
  disableArtistFilter,
  disableGenreFilter,
  hasMoreArtists,
  isLoadingMoreArtists,
  onLoadMoreArtistsClicked,
  selectedArtistId,
  selectedTaxonId,
  taxa,
}: FilterBarProps) {
  const router = useRouter()

  async function handleResetArtistFilter() {
    const { artist: _artist, ...query } = router.query
    await router.replace({ query }, undefined, {
      shallow: true,
    })
  }

  async function handleResetTaxonFilter() {
    const { genre: _genre, ...query } = router.query

    await router.replace({ query }, undefined, {
      shallow: true,
    })
  }

  async function handleArtistSelected(selectedArtist: Customer) {
    await router.replace(
      { query: { ...router.query, artist: selectedArtist.uuidAsString } },
      undefined,
      {
        shallow: true,
      }
    )
  }

  async function handleTaxonSelected(selectedTaxon: Taxon) {
    await router.replace({ query: { ...router.query, genre: selectedTaxon.code } }, undefined, {
      shallow: true,
    })
  }

  return (
    <Flex direction={['column', 'column', 'row', 'row', 'row']} gap={4}>
      {!disableArtistFilter ? (
        <ArtistFilter
          artists={artists ?? []}
          hasMoreArtists={hasMoreArtists}
          isLoadingMoreArtists={isLoadingMoreArtists}
          onArtistSelected={handleArtistSelected}
          onLoadMoreArtistsClicked={onLoadMoreArtistsClicked}
          onResetArtistFilter={handleResetArtistFilter}
          selectedArtistId={selectedArtistId}
        />
      ) : null}
      {!disableGenreFilter ? (
        <TaxonFilter
          onResetTaxonFilter={handleResetTaxonFilter}
          onTaxonSelected={handleTaxonSelected}
          selectedTaxonId={selectedTaxonId}
          taxa={taxa ?? []}
        />
      ) : null}
    </Flex>
  )
}
